.App {
  text-align: center;
}

.alice-carousel {
  background-color: black;
  padding: 20px 0;
}
.alice-carousel__prev-btn,
.alice-carousel__prev-btn-item,
.alice-carousel__next-btn,
.alice-carousel__next-btn-item {
  display: none!important;
}

.text-small {
  font-size: 10px;
  font-weight: 500;
}
